import "babel-polyfill";
import promise from "es6-promise";

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()

Es6Promise.polyfill()
promise.polyfill();
import cssVars from 'css-vars-ponyfill'
cssVars({})
import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

import ElementUI from 'element-ui';
import '@/style.css';
import 'element-ui/lib/theme-chalk/index.css';

import axios from 'axios';

import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

import '@/assets/icon/iconfont.js' //引入阿里巴巴图标库js
import '@/assets/icon/iconfont.css'//引入阿里巴巴图标库css

// swiper版本5
import css from 'swiper/css/swiper.min.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// 第二个参数可配置默认选项
export default () => {
  Vue.use(VueAwesomeSwiper, {
    css
  })
}

// 小程序 二维码弹窗
import LoginPop from '@/components/LoginPop/index.vue';
Vue.prototype.$LoginPop = () => {
  const LoginPopComponent = Vue.extend(LoginPop);
  const instance = new LoginPopComponent();
  instance.$mount();
  document.body.appendChild(instance.$el);
  return instance;
};

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");