import Vue from "vue";
import VueRouter from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
      name: 'index',
      path: '/index',
      component: () => import("@/views/index.vue"),
      children: [
          {
              path: '/home',
              name: 'home',
              component: () => import('@/views/home.vue'),
              meta: {
                  locale: '首页',
                  requiresAuth: false,
              },
          },
          {
              path: '/searDetail',
              name: 'searDetail',
              component: () => import('@/views/searDetail.vue'),
              meta: {
                  locale: '资讯详情',
                  requiresAuth: false,
              },
          },{
              path: '/detail/:publish_id',
              name: 'detail',
              component: () => import('@/views/detail.vue'),
              meta: {
                  locale: '资讯详情',
                  requiresAuth: false,
              },
          },{
              path: '/plateIndex/:plate_id',
              name: 'plateIndex',
              component: () => import('@/views/plateIndex.vue'),
              meta: {
                  locale: '板块首页',
                  requiresAuth: false,
              },
          },{
              path: '/userDetail/index/:user_id',
              name: 'userDetail',
              component: () => import('@/views/userDetail/index.vue'),
              meta: {
                  locale: '用户首页',
                  requiresAuth: false,
              },
          },
          
          {
              path: '/rankPages/publishRank/:pageName',
              name: 'publishRank',
              component: () => import('@/views/rankPages/publishRank.vue'),
              meta: {
                  locale: '内容排行',
                  requiresAuth: false,
              },
          },
          {
              path: '/rankPages/plateRank/:pageName',
              name: 'plateRank',
              component: () => import('@/views/rankPages/plateRank.vue'),
              meta: {
                  locale: '板块排行',
                  requiresAuth: false,
              },
          },
          {
              path: '/rankPages/userRank/:pageName',
              name: 'userRank',
              component: () => import('@/views/rankPages/userRank.vue'),
              meta: {
                  locale: '用户排行',
                  requiresAuth: false,
              },
          },
      ],
  },
];

const router = new VueRouter({
    routes,
});

const routerPush = VueRouter.prototype.push;

router.beforeEach((to, from, next) => {
    if (to.path == from.path) {
        return
    }
    next();
});

VueRouter.prototype.push = function (location) {
    return routerPush.call(this, location).catch(err => {})
};

Vue.use(VueRouter);

export default router;
