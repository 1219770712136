<template>
    <div class="pop_wrap" @click="closePop" v-show="showPop">
      <div class="pop_mask" :class="showPop ? 'show_pop_mask' : 'hide_pop_mask'">
        <div class="pop_box ez-overflow-hidden ez-p-20" :class="showPop ? 'show_pop_box' : 'hide_pop_box'">
          <img src="@/assets/images/program.jpg" alt="">
        </div>
      </div>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        showPop: false,
      };
    },
    methods: {
      openPop() {
        this.showPop = true;
      },
      closePop() {
        this.showPop = false;
      },
    },
  };
  </script>
  

<style lang="scss">
.pop_wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 3s linear;
  z-index: 999;
  .pop_mask {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: relative;
    .pop_box {
      position: absolute;
      width: 300px;
      height: 300px;
      background: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
    }
  }
  .show_pop_mask {}
  .hide_pop_mask {}
  .show_pop_box {}
  .hide_pop_box {}
}
</style>
